import { React, useState, useEffect } from 'react'
import styles from './GenderAgePanel.module.scss'
import ColumnChart from '../../../components/ColumnChart'
import GenderAge from '../../../components/ColumnChart/GenderAgeCountSurveillance'
import { useGeoFetch } from '../../../components/GeoProvider'
import ErrorMessage from '../../../components/Layout/ErrorMessage'
import { useAuthState } from '../../../components/AuthProvider'
import { useGeoState } from '../../../components/GeoProvider'
import Spinner from '../../../components/Loader/Spinner'
const GenderAgePanel = ({ geo_type, geo_id, data_type }) => {
    
  const {displayDate,
    seasonSelected,
    diseaseSelectedSurv,
    posSelected,
    lobSelected}=useGeoState()
  const { user } = useAuthState()
  const [genderAge, setGenderAge] = useState()
  const [errorCode, setErrorCode] = useState()
  const [isLoading, setIsLoading] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)

  const { data: genderAgeData, error: genderAgeDataError } = useGeoFetch(
    '',
    '',
    'surveillancenew',
    `suragegender?geo_type=${geo_type}&season=${seasonSelected}&disease=${diseaseSelectedSurv}&pos_id=${posSelected}&lob_id=${lobSelected}`
  )

  useEffect(() => {
    setIsLoading(true)
    if (genderAgeData !== null && genderAgeData !== undefined) {      
      if (genderAgeData.response_code === 200) {        
        setGenderAge(genderAgeData.response_data)
      } else {
        setErrorCode(genderAgeData.response_code)
        setErrorMessage(genderAgeData.response_message)
        setErrorSubject(genderAgeData.response_subject)          
      }              
        setIsLoading(false)
    }
  }, [genderAgeData])

  if ( errorOccured || genderAgeDataError ) {
    return (
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={''}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
    )
  }

  return  (
    <div className={styles.base}>
      <div className={styles.maindivFormat}>
        <div>
          <span className={styles.captionFormat}>
            Age & Gender
          </span>
        </div>

        <div>
          {genderAge !== undefined &&isLoading!==true ? (
            (genderAge[displayDate]!==undefined &&
              JSON.parse(Object.entries(genderAge[displayDate])[0][1].json_value)?<>
              <ColumnChart  data={Object.entries(genderAge[displayDate])} chartName="genderAgechartSurv" renderType="surv_dashboard" />
              <GenderAge data={JSON.parse(Object.entries(genderAge[displayDate])[0][1].json_value)} chartName="genderAgechartSurv"  renderType="dashboard" ></GenderAge>
            </>: <p className={styles.subCaptionFormat}>Data Not Available</p>)
            
          ) :<Spinner/>}
        </div>
      </div>
    </div>
  ) 
}

export default GenderAgePanel
