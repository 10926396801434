import React, { useState, useEffect } from 'react'
import { useGeoFetch } from '../../components/GeoProvider'
import GeoProvider from '../../components/GeoProvider'
import Content from '../../components/ContentPharma'
import Layout from '../../components/Layout'
import styles from './Surveillance.module.scss'

import { useAuthState } from '../../components/AuthProvider'

import { startDate, endDate ,displayDate} from '../Surveillance/dates'
import dataDisplayOptions from './dataDisplayOptions'
import HeaderTab from './HeaderTab'
import HeaderTitle from './HeaderTab/HeaderTitle'
import HeaderInfo from './HeaderTab/HeaderInfo'
import DashboardMap from './DashboardMap'
import Gender from './GenderAge'
import IncidenceGraph from './IncidenceGraph'


const SurveillanceNew = () => {
   const entity = 'surveillancenew'
   const [getSeasonData, setSeasonData] = useState()
   const [getDiseaseData, setDiseaseData] = useState()

  const { user } = useAuthState()
  const { data: seasonData, error: seasonDataError } = useGeoFetch(
    '',
    '',
    entity,
    'staticdata/season'
  )

  useEffect(() => {
    if (seasonData !== undefined) {
     
      if (seasonData.response_code === 200) {        
        setSeasonData(seasonData.response_data)
     } 
    }
  }, [seasonData])

  const { data: diseaseData, error: diseaseDataError } = useGeoFetch(
    '',
    '',
    entity,
    'staticdata/disease'
  )

  useEffect(() => {
    if (diseaseData !== undefined) {
     
      if (diseaseData.response_code === 200) {        
        setDiseaseData(diseaseData.response_data)
     }
    }
  }, [diseaseData])

  return (
    <Layout>
      <Content>
        <div className={styles.base}>
          <div className={styles.body}>
            <GeoProvider
              startDate={startDate}
              endDate={endDate}
              displayDate={'1991-01-01'}
              mapOptions={dataDisplayOptions[0]['options'][0]}
              indicatorName={dataDisplayOptions[0]['options'][0].indicatorName}
              regionSelected={'STATE'}
              seasonSelected={'2024-25'}
            >
              <HeaderInfo/>
              {getSeasonData!==undefined&&getDiseaseData!==undefined&&
              <HeaderTab seasonData={getSeasonData} diseaseData={getDiseaseData}/>}
              {getSeasonData!==undefined&&
              <HeaderTitle seasonData={getSeasonData} />}
              <DashboardMap />
              <Gender geo_type="NAT" geo_id="NAT" data_type="" />
              {/* <IncidenceGraph/> */}
            </GeoProvider>
         
          </div>
        </div>
      </Content>
    </Layout>
  )
}

export default SurveillanceNew
