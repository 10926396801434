import React, { useState, useEffect, useRef } from 'react'
import { useGeoState, useGeoFetch } from '../../components/GeoProvider'
import ErrorMessage from '../../components/Layout/ErrorMessage'
import DisplayDateSurveillance from '../../components/DisplayDateSurveillance'
import Spinner from '../../components/Loader/Spinner'
import EmptyMap from '../../components/MapPharma/emptyMap'
import Timeline from '../../components/TimelineSurveillance'
import Map from '../../components/MapPharma'
import toolTipFormatter from './toolTipFormatter'
import PosMapLegend from './survPosMapLegendVertical'
import MapZoomToggle from './mapZoomToggle'
import SideTable from './SideTable'
import SideFullTable from './SideFullTable'
import styles from './MapPage.module.scss'
import moment from 'moment'
import UHCMain from './UHCCoverageMap/UHCMain'
import MapLegend from './cdcMapLegendVertical'
// import { displayDate } from '../Forecasts/dates'
const entity = 'surveillancenew'
function getDynamicColorLegend(posId, diseaseId, dashType, region) {
  if (dashType === 'SURV') {
    if (region.toUpperCase() === 'ZIP3') {
        return true
    } 
    else {
      return false
    }
  } 
  else{return false}
}

// function getDynamicColorLegend(posId, diseaseId, dashType, region) {
//   if (dashType === 'SURV') {
//     if (diseaseId.toUpperCase() === 'FLU') {
//       if (posId.toString() !== '0') {
//         return true
//       } else {
//         return false
//       }
//     } else if (
//       diseaseId.toUpperCase() === 'COVID-19' ||
//       diseaseId.toUpperCase() === 'RSV'
//     ) {
//       if (posId.toString() !== '0') {
//         return true
//       } else {
//         if (region.toUpperCase() === 'ZIP3') {
//           return true
//         } else {
//           return false
//         }
//       }
//     } else {
//       return true
//     }
//   } else {
//     return false
//   }
// }

const StateComponentAPI = ({
  stateDataValue,
  maxDateValue,
  setStateLoadingValue,
  setErrorOccuredValue
}) => {
  const [getMapStates] = useState(undefined)
  const {
    isChecked,
    showFullTable,
    displayDate,
    setDisplayDate,
    seasonSelected,
    posSelected,
    lobSelected,
    diseaseSelectedSurv,
  } = useGeoState()
  const { data: statesData, error: MapError } = useGeoFetch(
    '',
    '',
    entity,
    `mapwithstats?geo_type=STATE&season=${seasonSelected}&disease=${diseaseSelectedSurv}&pos=${posSelected}&lob=${lobSelected}`
  )

  useEffect(() => {
    setStateLoadingValue(true)
    if (statesData !== null && statesData !== undefined && statesData !== '') {
      if (statesData.response_code === 200) {
        var response = statesData.response_data
        let maxDateStamp = Math.max(
          ...Object.keys(response).map((member) => Date.parse(member))
        )
        if(displayDate==='1991-01-01' || isChecked===true)
        {
          setDisplayDate(moment(maxDateStamp).utc().format('YYYY-MM-DD'))
        }
        maxDateValue(moment(maxDateStamp).utc().format('YYYY-MM-DD'))
        stateDataValue(response)
      } else {
        let er={"errormessage":statesData.response_message,
          "errorcode":statesData.response_code,
          "errorsubject":statesData.response_subject,
          "erroroccured":true
        }
        setErrorOccuredValue(er)
      }
      setStateLoadingValue(false)
    }
  }, [statesData, getMapStates])

 
  return <></>
}

const StateStatsSideTableComponentAPI = ({
  sideStateDataValue,
  setSideStateLoadingValue,
  setErrorOccuredValue
}) => {
  const [getSideTableStates] = useState(undefined)
  const {
    seasonSelected,
    posSelected,
    lobSelected,
    diseaseSelectedSurv,
  } = useGeoState()
  

  const { data: statesSideData, error: SideDataError } = useGeoFetch(
    '',
    '',
    entity,
    `sidetablewithstats?geo_type=STATE&season=${seasonSelected}&disease=${diseaseSelectedSurv}&pos=${posSelected}&lob=${lobSelected}`
  )

  useEffect(() => {
    setSideStateLoadingValue(true)
    if (statesSideData !== null && statesSideData !== undefined) {
      if (statesSideData.response_code === 200) {
        sideStateDataValue(statesSideData.response_data)
      } else {
        let er={"errormessage":statesSideData.response_message,
          "errorcode":statesSideData.response_code,
          "errorsubject":statesSideData.response_subject,
          "erroroccured":true
        }
        setErrorOccuredValue(er)
      }
      setSideStateLoadingValue(false)
    }
  }, [statesSideData, getSideTableStates])

  return <></>
}
const MSAComponentAPI = ({ msaDataValue,setMSALoadingValue,setErrorOccuredValue }) => {
  const [metros] = useState(undefined)
  const {
    seasonSelected,
    posSelected,
    lobSelected,
    diseaseSelectedSurv,
  } = useGeoState()
  const { data: msaData, error: MsaDataError } = useGeoFetch(
    '',
    '',
    entity,
    `mapwithstats?geo_type=MSA&season=${seasonSelected}&disease=${diseaseSelectedSurv}&pos=${posSelected}&lob=${lobSelected}`
  )

  useEffect(() => {
    setMSALoadingValue(true)
    if (msaData !== null && msaData !== undefined && msaData !== '') {
      if (msaData.response_code === 200) {
        var response = msaData.response_data
        msaDataValue(response)
      } else {
        let er={"errormessage":msaData.response_message,
          "errorcode":msaData.response_code,
          "errorsubject":msaData.response_subject,
          "erroroccured":true
        }
        setErrorOccuredValue(er)
      }
      setMSALoadingValue(false)
    }
  }, [msaData, metros])

  
  return <></>
}
const MSAStatsSideTableComponentAPI = ({  sideMSADataValue,setSideMSALoadingValue ,setErrorOccuredValue}) => {
  const [getMetroSideTable] = useState(undefined)
  const {
    seasonSelected,
    posSelected,
    lobSelected,
    diseaseSelectedSurv,
  } = useGeoState()
  

  const { data: msaSideData, error: msaSideDataError } = useGeoFetch(
    '',
    '',
    entity,
    `sidetablewithstats?geo_type=MSA&season=${seasonSelected}&disease=${diseaseSelectedSurv}&pos=${posSelected}&lob=${lobSelected}`
  )

  useEffect(() => {
    setSideMSALoadingValue(true)
    if (msaSideData !== null && msaSideData !== undefined) {
      if (msaSideData.response_code === 200) {
        sideMSADataValue(msaSideData.response_data)
      } else {
        let er={"errormessage":msaSideData.response_message,
          "errorcode":msaSideData.response_code,
          "errorsubject":msaSideData.response_subject,
          "erroroccured":true
        }
        setErrorOccuredValue(er)
      }
      setSideMSALoadingValue(false)
    }
  }, [msaSideData, getMetroSideTable])
  
  return <></>
}
const Zip3ComponentAPI = ({ zip3DataValue,setZip3LoadingValue,setErrorOccuredValue }) => {

  const [zip3] = useState(undefined)
  const {
    seasonSelected,
    posSelected,
    lobSelected,
    diseaseSelectedSurv,
  } = useGeoState()
  const { data: zip3Data, error: ZipDataError } = useGeoFetch(
    '',
    '',
    entity,
    `mapwithstats?geo_type=ZIP3&season=${seasonSelected}&disease=${diseaseSelectedSurv}&pos=${posSelected}&lob=${lobSelected}`
  )

  useEffect(() => {
    setZip3LoadingValue(true)

    if (zip3Data !== null && zip3Data !== undefined && zip3Data !== '') {
      if (zip3Data.response_code === 200) {
        var response = zip3Data.response_data
      
        zip3DataValue(response)
      } else {
        let er={"errormessage":zip3Data.response_message,
          "errorcode":zip3Data.response_code,
          "errorsubject":zip3Data.response_subject,
          "erroroccured":true
        }
        setErrorOccuredValue(er)
      }
      setZip3LoadingValue(false)
    }
  }, [zip3Data, zip3])

 
  return <></>
}
const Zip3StatsSideTableComponentAPI = ({ sideZip3DataValue,setSideZip3LoadingValue,setErrorOccuredValue }) => {

  const [getZip3SideTable] = useState(undefined)
  const {
    seasonSelected,
    posSelected,
    lobSelected,
    diseaseSelectedSurv,
  } = useGeoState()

  const { data: zip3SideData, error: zip3SideDataError } = useGeoFetch(
    '',
    '',
    entity,
    `sidetablewithstats?geo_type=ZIP3&season=${seasonSelected}&disease=${diseaseSelectedSurv}&pos=${posSelected}&lob=${lobSelected}`
  )
  useEffect(() => {
    setSideZip3LoadingValue(true)
    if (zip3SideData !== null && zip3SideData !== undefined && zip3SideData !== '') {
      if (zip3SideData.response_code === 200) {
        sideZip3DataValue(zip3SideData.response_data)
      } 
      else {
        let er={"errormessage":zip3SideData.response_message,
          "errorcode":zip3SideData.response_code,
          "errorsubject":zip3SideData.response_subject,
          "erroroccured":true
        }
        setErrorOccuredValue(er)
      }
      setSideZip3LoadingValue(false)
    }
  }, [zip3SideData, getZip3SideTable])

  return <></>
}
function checkSideTableShow(lob,pos)
{
  let visible=true
  if(lob==='0' || pos==='0')
  {
        visible=true  
  }
  else if(lob!=='0')
  {
    if(pos!=='0')
    visible=false
    else
    visible=true
  }
  else
  {
      visible=true
  }
  return visible
}
const DashboardMap = ({ zoomLocation }) => {  
  const chartRef = useRef(null)
  const slotTimeInSeconds = 604800000
  const latestPositionString = ''
  const slotString = 'week'
  const [getMapStates, setMapStates] = useState(undefined)
  const [getSideTableStates, setSideTableStates] = useState(undefined)
  const [activeCbsa, setActiveCbsa] = useState()
  const [activeZip3, setActiveZip3] = useState()
  const [metros, setMetros] = useState(undefined)
  const [getMetroSideTable, setMetroSideTable] = useState(undefined)
  const [zip3, setZip3] = useState(undefined)
  const [getZip3SideTable, setZip3SideTable] = useState(undefined)
  const [isStateLoading, setIsStateLoading] = useState(false)
  const [isMSALoading, setIsMSALoading] = useState(false)
  const [isZip3Loading, setIsZip3Loading] = useState(false)

  const [isStateSideLoading, setIsStateSideLoading] = useState(false)
  const [isMSASideLoading, setIsMSASideLoading] = useState(false)
  const [isZip3SideLoading, setIsZip3SideLoading] = useState(false)

  const [errorCode, setErrorCode] = useState()
  const [errorMessage, setErrorMessage] = useState()
  const [errorSubject, setErrorSubject] = useState()
  const [errorOccured, seterrorOccured] = useState(false)
  const [getStateJson, setStateJson] = useState()
  const [getCBSAJsonData, setCBSAJsonData] = useState()
  const [maxDate, setMaxDate] = useState()
  const [getPosOptions, setPosOptions] = useState()
  const [getLobOptions, setLobOptions] = useState()
  const [getzip3Json, setzip3Json] = useState()
  const [getErrorObject, setErrorObject] = useState()

  const {
    mapOptions,
    regionSelected,
    showFullTable,
    showUHCCoverage,
    posSelected,
    diseaseSelectedSurv,
    getMapZoom,
    lobSelected
  } = useGeoState()

  const { data: staticData, error: staticDataError } = useGeoFetch(
    '',
    '',
    entity,
    `staticdata/pos`
  )

  useEffect(() => {
    if (staticData !== null && staticData !== undefined) {
      if (staticData.response_code === 200) {
        setPosOptions(staticData.response_data)
      } else {
        setErrorCode(staticData.response_code)
        setErrorMessage(staticData.response_message)
        setErrorSubject(staticData.response_subject)
      }
    }
  }, [staticData])

  const { data: staticLobData, error: staticLobDataError } = useGeoFetch(
    '',
    '',
    entity,
    `staticdata/lob`
  )

  useEffect(() => {
    if (staticLobData !== null && staticLobData !== undefined) {
      if (staticLobData.response_code === 200) {
        setLobOptions(staticLobData.response_data)
      } else {
        setErrorCode(staticLobData.response_code)
        setErrorMessage(staticLobData.response_message)
        setErrorSubject(staticLobData.response_subject)
      }
    }
  }, [staticLobData])

  // Active Metro Areas
  const { data: activemsaData, error: activeMSAError } = useGeoFetch(
    '',
    '',
    entity,
    `activelocations?geo_type=MSA`
  )

  useEffect(() => {
    if (
      activemsaData !== null &&
      activemsaData !== undefined &&
      activemsaData !== ''
    ) {
      if (activemsaData.response_code === 200) {
        setActiveCbsa(activemsaData.response_data)
      } else {
        setErrorCode(activemsaData.response_code)
        setErrorMessage(activemsaData.response_message)
        setErrorSubject(activemsaData.response_subject)
      }
    }
  }, [activemsaData])

  //active zip3
  const { data: activeZipData, error: activeZipError } = useGeoFetch(
    '',
    '',
    entity,
    `activelocations?geo_type=ZIP3`
  )

  useEffect(() => {
    if (
      activeZipData !== null &&
      activeZipData !== undefined &&
      activeZipData !== ''
    ) {
      if (activeZipData.response_code === 200) {
        setActiveZip3(activeZipData.response_data)
      } else {
        setErrorCode(activeZipData.response_code)
        setErrorMessage(activeZipData.response_message)
        setErrorSubject(activeZipData.response_subject)
      }
    }
  }, [activeZipData])

  useEffect(() => {
    let url = `${window.location.origin}/usStatesAll.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setStateJson(data)
      })
      .catch(function (err) {
        console.log(err, ' error file usStatesAll.json')
      })
  }, [1])
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setCBSAJsonData(data)
      })
      .catch(function (err) {
        console.log(err, ' error file us_hrr_values ')
      })
  }, [1])

  useEffect(() => {
    let url = `${window.location.origin}/three_dig_zips.json`
    fetch(url)
      .then(function (res) {
        return res.json()
      })
      .then(function (data) {
        setzip3Json(data)
      })
      .catch(function (err) {
        console.log(err, ' error file load us_hrr_values ')
      })
  }, [1])
  if (
    errorOccured ||    
    staticDataError ||
    activeZipError ||
    activeMSAError
  ) {
    return (
      <div style={{padding:'0px 0px 24px 0px'}}>
      <ErrorMessage
        errorCode={errorCode}
        errorMessage={errorMessage}
        errorSubject={errorSubject}
        errorDetails={errorMessage}
      />
       </div>
    )
  }
  if(getErrorObject!==undefined)
    {
      return (
        <div style={{padding:'0px 0px 24px 0px'}}>
        <ErrorMessage
          errorCode={getErrorObject.errorcode}
          errorMessage={getErrorObject.errormessage}
          errorSubject={getErrorObject.errorsubject}
          errorDetails={getErrorObject.errormessage}
        />
        </div>
      )
    }

  const setStateData = (statedata) => {
    setMapStates(statedata)
  }

  const setSideStateData = (statesidedata) => {
    setSideTableStates(statesidedata)
  }

  const setMSAData = (msadata) => {
    setMetros(msadata)
  }

  const setSideMSAData = (msadata) => {
    setMetroSideTable(msadata)
  }

  const setZIP3Data = (zipdata) => {
    setZip3(zipdata)
  }

  const setSideZip3Data = (zipdata) => {
    setZip3SideTable(zipdata)
  }
  const setMaxStateDate = (max) => {
    setMaxDate(max)
  }
  const setStateLoading=(load)=>{
    setIsStateLoading(load)
  }
  const setSideStateLoading=(load)=>{
    setIsStateSideLoading(load)
  }

  const setMSALoading=(load)=>{
    setIsMSALoading(load)
  }
  const setSideMSALoading=(load)=>{
    setIsMSASideLoading(load)
  }

  const setZip3Loading=(load)=>{
    setIsZip3Loading(load)
  }
  const setSideZip3Loading=(load)=>{
    setIsZip3SideLoading(load)
  }
  const setErrorObjectData=(load)=>{
    setErrorObject(load)
  }
  
  return (
    <div className={`${styles.base}`}>
      {showUHCCoverage ? (
        <UHCMain />
      ) : (
        <>
      
          {getMapStates !== undefined &&
            getMapStates[maxDate] !== undefined &&
            getPosOptions !== undefined &&
            getLobOptions !== undefined && (
              <div className={styles.fullTimelinePanel}>
                <DisplayDateSurveillance
                  posOptions={getPosOptions}
                  lobOptions={getLobOptions}
                />
                <Timeline
                  statesData={getMapStates}
                  latestPositionString={latestPositionString}
                  slotString={slotString}
                  slotTimeInSeconds={slotTimeInSeconds}
                  markCounts={7}
                  maxDate={maxDate}
                />
              </div>
            )}
          {showFullTable && checkSideTableShow(posSelected.toString(),lobSelected.toString())? (
            //#region Full details table on toggle
            <>
              {regionSelected.toUpperCase() === 'STATE' && (
                <>
                <StateComponentAPI stateDataValue={setStateData}
                     maxDateValue={setMaxStateDate}
                     setStateLoadingValue={setStateLoading}
                     setErrorOccuredValue={setErrorObjectData}
                     />
                <StateStatsSideTableComponentAPI 
                sideStateDataValue={setSideStateData}
                setSideStateLoadingValue={setSideStateLoading}
                maxDateValue={setMaxStateDate}
                setErrorOccuredValue={setErrorObjectData}
                />
                  {getSideTableStates !== undefined &&
                  getMapStates !== undefined &&
                  getMapStates[maxDate] !== undefined &&
                  isStateSideLoading !== true &&
                  getSideTableStates[maxDate] !== undefined&
                  getPosOptions !== undefined &&
                  getLobOptions !== undefined 
                   ? (
                    <div className={`${styles.datePanel}`}>
                      <SideFullTable
                        stateData={getMapStates}
                        jsonData={getSideTableStates}
                        chartRef={chartRef}
                        lobOptions={getLobOptions}
                        posOptions={getPosOptions}
                      />
                    </div>
                  ) : (
                    <div className={`${styles.datePanel}`}>
                     
                      {' '}
                      <Spinner />
                    </div>
                  )}
                </>
              )}
              {regionSelected.toUpperCase() === 'MSA' && (
                <>
                 
                <MSAComponentAPI
                      msaDataValue={setMSAData}
                      setMSALoadingValue={setMSALoading}
                      setErrorOccuredValue={setErrorObjectData}
                    />
                <MSAStatsSideTableComponentAPI sideMSADataValue={setSideMSAData} 
                setSideMSALoadingValue={setSideMSALoading}
                setErrorOccuredValue={setErrorObjectData}/>
                  {getMetroSideTable !== undefined &&
                  metros !== undefined &&
                  metros[maxDate] !== undefined &&
                  isMSASideLoading !== true &&
                  getMetroSideTable[maxDate] !== undefined ? (
                    <div className={`${styles.datePanel}`}>
                      <SideFullTable
                        stateData={metros}
                        jsonData={getMetroSideTable}
                        chartRef={chartRef}
                      />
                    </div>
                  ) : (
                    <div className={`${styles.datePanel}`}>
                      {' '}
                      <Spinner />
                    </div>
                  )}
                </>
              )}
              {regionSelected.toUpperCase() === 'ZIP3' && (
                <>
                 
                <Zip3ComponentAPI
                          zip3DataValue={setZIP3Data}
                          //sideZip3DataValue={setSideZip3Data}
                          setZip3LoadingValue={setZip3Loading}
                          setErrorOccuredValue={setErrorObjectData}
                        />
                <Zip3StatsSideTableComponentAPI sideZip3DataValue={setSideZip3Data} 
                setSideZip3LoadingValue={setSideZip3Loading}
                setErrorOccuredValue={setErrorObjectData}/>
                  {getZip3SideTable !== undefined &&
                  zip3 !== undefined &&
                  zip3[maxDate] !== undefined &&
                  isZip3SideLoading !== true &&
                  getZip3SideTable[maxDate] !== undefined ? (
                    <div className={`${styles.datePanel}`}>
                      <SideFullTable
                        stateData={zip3}
                        jsonData={getZip3SideTable}
                        chartRef={chartRef}
                      />
                    </div>
                  ) : (
                    <div className={`${styles.datePanel}`}>
                      {' '}
                      <Spinner />
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            //#endregion
            //#region SIDE Stats Table & MAP
            <>
              <div className={`${styles.cbsasPanel}`}>
                <>
                  {regionSelected.toUpperCase() === 'STATE' && (
                    <>
                      {getMapStates !== undefined && isStateLoading !== true ? (
                        <SideTable
                          stateData={getMapStates}
                          chartRef={chartRef}
                        />
                      ) : (
                        <div
                          style={{
                            border: '1.5px solid #CBCCCD',
                            height: '84vh',
                          }}
                        >
                          <Spinner />
                        </div>
                      )}
                    </>
                  )}
                  {regionSelected.toUpperCase() === 'MSA' && (
                    <>
                      {metros !== undefined &&
                      metros[maxDate] !== undefined &&
                      isMSALoading !== true ? (
                        <SideTable stateData={metros} chartRef={chartRef} />
                      ) : (
                        <div
                          style={{
                            border: '1.5px solid #CBCCCD',
                            height: '84vh',
                          }}
                        >
                          <Spinner />
                        </div>
                      )}
                    </>
                  )}
                  {regionSelected.toUpperCase() === 'ZIP3' && (
                    <>
                      {zip3 !== undefined &&
                      zip3[maxDate] !== undefined &&
                      isZip3Loading !== true ? (
                        <SideTable stateData={zip3} chartRef={chartRef} />
                      ) : (
                        <div
                          style={{
                            border: '1.5px solid #CBCCCD',
                            height: '84vh',
                          }}
                        >
                          <Spinner />
                        </div>
                      )}
                    </>
                  )}
                </>
              </div>

              <div className={`${styles.map}`}>
                {regionSelected.toUpperCase() === 'STATE' && (
                  <>
                    <StateComponentAPI
                    stateDataValue={setStateData}
                    // sideStateDataValue={setSideStateData}
                      maxDateValue={setMaxStateDate}
                      setStateLoadingValue={setStateLoading}
                      setErrorOccuredValue={setErrorObjectData}
                    />
                    {getMapStates !== undefined &&
                    isStateLoading !== true &&
                    getStateJson ? (
                      <>
                      <MapLegend/>
                        {/* {getDynamicColorLegend(
                          posSelected,
                          diseaseSelectedSurv,
                          'SURV',
                          regionSelected
                        ) ? (
                          <PosMapLegend dataSet={getMapStates} />
                        ) : (
                          <MapLegend />
                        )} */}
                        <Map
                          toolTipFormatter={toolTipFormatter}
                          activeCbsasData={null}
                          statesData={getMapStates}
                          cbsaData={null}
                          mapOptions={mapOptions}
                          dynamicMapTitle={true}
                          mapType="RSVMAP"
                          enableButtons={true}
                          chartRef={chartRef}
                          mapDataType={0}
                          stateJson={getStateJson}
                          dashboardType="SURV"
                          mapHeight="90vh"
                          posTrue={posSelected.toString()}
                          enableMouseZoom={getMapZoom}
                        />
                        <MapZoomToggle className={styles.mapZoomToggle} />
                      </>
                    ) : (
                      <EmptyMap stateJson={getStateJson} />
                    )}
                  </>
                )}
                {regionSelected.toUpperCase() === 'MSA' && (
                  <>
                 <StateComponentAPI
                      stateDataValue={setStateData}
                      maxDateValue={setMaxStateDate}
                      setStateLoadingValue={setStateLoading}
                      setErrorOccuredValue={setErrorObjectData}
                    />
                    <MSAComponentAPI
                      msaDataValue={setMSAData}
                      sideMSADataValue={setSideMSAData}
                      setMSALoadingValue={setMSALoading}
                      setErrorOccuredValue={setErrorObjectData}
                    />
                    {metros !== undefined &&
                    getMapStates !== undefined &&
                    getPosOptions !== undefined &&
                    isMSALoading !== true &&
                    activeCbsa !== undefined &&
                    metros[maxDate] !== undefined &&
                    getCBSAJsonData ? (
                      <>
                         <MapLegend/>
                        {/* {getDynamicColorLegend(
                          posSelected,
                          diseaseSelectedSurv,
                          'SURV',
                          regionSelected
                        ) ? (
                          <PosMapLegend dataSet={metros} />
                        ) : (
                          <MapLegend />
                        )} */}
                        <Map
                          toolTipFormatter={toolTipFormatter}
                          activeCbsasData={activeCbsa}
                          statesData={getMapStates}
                          cbsaData={metros}
                          mapOptions={mapOptions}
                          dynamicMapTitle={true}
                          mapType="RSVMAPMSA"
                          enableButtons={true}
                          zoomLocation={zoomLocation}
                          chartRef={chartRef}
                          mapDataType={1}
                          mapHeight="90vh"
                          JsonData={getCBSAJsonData}
                          stateJson={getStateJson}
                          dashboardType="SURV"
                          posTrue={posSelected.toString()}
                          enableMouseZoom={getMapZoom}
                        />
                        <MapZoomToggle className={styles.mapZoomToggle} />
                      </>
                    ) : (
                      <>
                       <EmptyMap stateJson={getStateJson} />
                      </>
                     
                    )}
                  </>
                )}
                {regionSelected.toUpperCase() === 'ZIP3' && (
                  <>
                 <StateComponentAPI
                      stateDataValue={setStateData}
                      maxDateValue={setMaxStateDate}
                      setStateLoadingValue={setStateLoading}
                      setErrorOccuredValue={setErrorObjectData}
                    />
                   <Zip3ComponentAPI
                          zip3DataValue={setZIP3Data}
                          setZip3LoadingValue={setZip3Loading}
                          setErrorOccuredValue={setErrorObjectData}
                          //sideZip3DataValue={setSideZip3Data}
                        />
                    {zip3 !== undefined &&
                    getMapStates !== undefined &&
                    activeZip3 !== undefined &&
                    isZip3Loading !== true &&
                    getPosOptions !== undefined &&
                    zip3[maxDate] !== undefined &&
                    getzip3Json ? (
                      <>
                       <PosMapLegend dataSet={zip3} />
                        {/* {getDynamicColorLegend(
                          posSelected,
                          diseaseSelectedSurv,
                          'SURV',
                          regionSelected
                        ) ? (
                          <PosMapLegend dataSet={zip3} />
                        ) : (
                          <MapLegend />
                        )} */}
                        <Map
                          toolTipFormatter={toolTipFormatter}
                          activeZip3Data={activeZip3}
                          statesData={getMapStates}
                          cbsaData={null}
                          mapOptions={mapOptions}
                          dynamicMapTitle={true}
                          mapType="ZIP3MAP"
                          enableButtons={true}
                          zoomLocation={zoomLocation}
                          chartRef={chartRef}
                          mapDataType={1}
                          mapHeight="90vh"
                          JsonData={getCBSAJsonData}
                          stateJson={getStateJson}
                          dashboardType="SURV"
                          zip3Json={getzip3Json}
                          zip3Data={zip3}
                          posTrue={posSelected.toString()}
                          enableMouseZoom={getMapZoom}
                        />
                        <MapZoomToggle className={styles.mapZoomToggle} />
                      </>
                    ) : (
                      <EmptyMap stateJson={getStateJson} />
                    )}
                  </>
                )}
              </div>
            </>
            //#endregion
          )}
        </>
      )}
    </div>
  )
}

export default DashboardMap
