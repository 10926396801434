import { useHistory } from 'react-router-dom'
import React, { useState } from 'react'

import { useGeoState } from '../../../components/GeoProvider'
import Spinner from '../../../components/Loader/Spinner'
import LinkButton from '../../../components/LinkButton'
import Icon from '../../../components/Icon'

import CovidHRRCaseTrend from '../CovidHRRCaseTrend'

import hrrMap from '../../../utils/hrrsMap'
import CheckBox from '../../../components/CheckBox'

import styles from './CovidDetailedView.module.scss'

const DetailedViewDisplay = ({ hrrData, type, identifier }) => {
  const { mapOptions, hrrDataWeekEndDate } = useGeoState()
  // const history = useHistory()
  const [getactualflag, setactualflag] = useState(true)
  const [getforecastflag, setforecastflag] = useState(true)

  let allDates = Object.keys(hrrData)
  let minDate = allDates[0]
  let minDateObj = new Date(minDate)
  allDates.forEach(function (dt, index) {
    if (new Date(dt) < minDateObj) {
      minDate = dt
      minDateObj = new Date(dt)
    }
  })

  const startDate = minDateObj

  let data = []

  switch (type) {
    case 'hrr':
      Object.entries(hrrData).forEach(function (dateObj) {
        const key = dateObj[0]
        const value = dateObj[1][identifier]
        data.push({ date: key, ...value })
      })
      break
    default:
    // code block
  }
  function handleChange(e) {
    let isChecked = e.target.checked
    let chkValue = e.target.value
    if (chkValue === 'Forecast' && isChecked === true) {
      setforecastflag(true)
    } else if (chkValue === 'Forecast' && isChecked === false) {
      setforecastflag(false)
    } else if (chkValue === 'Actual' && isChecked === true) {
      setactualflag(true)
    } else if (chkValue === 'Actual' && isChecked === false) {
      setactualflag(false)
    }
    // var filterData = data.filter((t) => t.cast_type === chkValue)
  }

  // let datatype
  // mapOptions['parent'] === 'UHC Indicators'
  //   ? (datatype = 'uhc')
  //   : (datatype = 'public')
  return (
    <>
      {/* <div className={styles.alignRight}>
        <LinkButton
          className={styles.viewMore}
          showArrow
          onClick={() => {
            history.push(`/covid-19/${type}/${identifier}#${datatype}`)
          }}
        >
          View More
        </LinkButton>
      </div> */}
      <div className={styles.checkboxPosition}>
        <CheckBox
          label={'Actual'}
          value={'Actual'}
          id={'Actual'}
          //checked="selected"
          className={styles.checkboxText}
          onChange={(e) => handleChange(e)}
          defaultChecked={true}
        ></CheckBox>
        <CheckBox
          label={'Forecast'}
          value={'Forecast'}
          id={'Forecast'}
          //selected={true}
          className={styles.checkboxText}
          onChange={(e) => handleChange(e)}
          defaultChecked={true}
        ></CheckBox>
      </div>
      {mapOptions['parent'] === 'Covid Hospitalization' ? (
        <CovidHRRCaseTrend
          startDate={startDate}
          area={data}
          displayDate={hrrDataWeekEndDate}
          forecastflag={getforecastflag}
          actualflag={getactualflag}
        />
      ) : (
        <div></div>
      )}
    </>
  )
}

const DetailedView = ({ hrrData, hash , hrrMapJson}) => {
  const area = hash.replace('#', '').split('-')
  const identifier = area[1]
  const type = area[0]

  const history = useHistory()
  let name = ''
  // let displayType = type
  var location =
    hrrMap(hrrMapJson)[identifier].split('-')[1] +
    ', ' +
    hrrMap(hrrMapJson)[identifier].split('-')[0]
  switch (type) {
    case 'hrr':
      name = location
      break
    default:
    // code block
  }

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <h3>
            {name}{' '}
            {/* <LinkButton
              className={styles.viewMore}
              onClick={() => {
                history.push(`${type}/${identifier}`)
              }}
              link
            >
              View more
            </LinkButton> */}
          </h3>

          {/* <span className={styles.position}>{displayType}</span> */}
        </div>
        <button
          onClick={() => {
            history.push({ hash: null })
          }}
        >
          <Icon type="remove" size={'1.5rem'} />
        </button>

        {/* <h3>{name}</h3>
        <span className={styles.position}>{displayType}</span>
        <button
          onClick={() => {
            history.push({ hash: null })
          }}
        >
          <Icon type="remove" size={'1.5rem'} />
        </button> */}
      </div>
      <div className={styles.body}>
        {type === 'hrr' &&
        hrrData !== undefined &&
        Object.keys(hrrData).length > 1 ? (
          <DetailedViewDisplay
            hrrData={hrrData}
            hash={hash}
            type={type}
            identifier={identifier}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  )
}

export default DetailedView
