import React, { useState, useEffect, useCallback } from 'react'
import { Link } from 'react-router-dom'

import AddSubscriptionForm from '../../../components/AddEditSubscription'
import Breadcrumbs from '../../../components/Breadcrumbs'
import Spinner from '../../../components/Loader/Spinner'

import Flex from '../../../components/Flex'

import handleResponse from '../../../utils/handleFetchResponse'

import styles from './AddEditSubscription.module.scss'

const AddSubscription = (props) => {
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL
  const subscriptionsUrl = `${baseUrl}/subscriptions`
  const activeCbsasFlu = `${baseUrl}/Subscription/fluactivecbsa`
  const activeCbsasCovid = `${baseUrl}/Subscription/covidactivecbsa`
  var token = window.localStorage.getItem('access_token')

  const [loading, setLoading] = useState({
    isLoading: false,
    hasLoaded: false,
    hasError: false,
  })
  const [activeCbsasFluLoading, setCbsasFluLoading] = useState({
    isCbsasFluLoading: false,
    hasCbsasFluLoaded: false,
    hasCbsasFluError: false,
  })
  const [activeCbsasCovidLoading, setCbsasCovidLoading] = useState({
    isCbsasCovidLoading: false,
    hasCbsasCovidLoaded: false,
    hasCbsasCovidError: false,
  })
  const { state } = props.location
  const [subscriptions, setSubscriptions] = useState({})
  const [activeFluCbsas, setActiveFluCbsas] = useState([])
  const [activeCovidCbsas, setActiveCovidCbsas] = useState([])
  const createSubscriptionsData = useCallback((data) => {
    let regionData = {
      FLU: { state: [], city: [] },
      'COVID-19': { state: [], city: [], county: [] },
    }
    for (const key in data) {
      const subscriptions = data[key].subscriptions
      for (let subscription of subscriptions) {
        if (subscription.infection_type === 'FLU') {
          if (subscription.geo_type === 'CITY') {
            regionData['FLU'].city.push(subscription.geo_id)
          } else {
            regionData['FLU'].state.push(subscription.geo_id)
          }
        }
        if (subscription.infection_type === 'COVID-19') {
          if (subscription.geo_type === 'CITY') {
            regionData['COVID-19'].city.push(subscription.geo_id)
          } else if (subscription.geo_type === 'STATE') {
            regionData['COVID-19'].state.push(subscription.geo_id)
          } else {
            regionData['COVID-19'].county.push(subscription.geo_id)
          }
        }
      }
    }
    setSubscriptions(regionData)
  }, [])
  const getListOfSubscriptions = useCallback(() => {
    setLoading((loading) => ({ ...loading, isLoading: true }))
    fetch(`${subscriptionsUrl}`,{
      method: 'get',
      headers: {
        'Content-Type': 'application/json',        
        //id_token: token,
      }
    }
    )
      .then((res) => handleResponse(res))
      .then((response) => {
        createSubscriptionsData(response)
        setLoading((loading) => ({
          ...loading,
          isLoading: false,
          hasLoaded: true,
        }))
      })
      .catch((err) => {
        setLoading((loading) => ({
          ...loading,
          hasError: true,
          isLoading: false,
        }))
      })
  }, [subscriptionsUrl, createSubscriptionsData])

  const getListOfAcitveCbsasFlu = useCallback(() => {
    setCbsasFluLoading((activeCbsasFluLoading) => ({
      ...activeCbsasFluLoading,
      isCbsasFluLoading: true,
    }))
    fetch(`${activeCbsasFlu}`,{
      method: 'get',
      headers: {
        'Content-Type': 'application/json',        
        id_token: token,
      }
    }
    )
      .then((res) => handleResponse(res))
      .then((response) => {
        setActiveFluCbsas(Object.values(response))
        setCbsasFluLoading((activeCbsasFluLoading) => ({
          ...activeCbsasFluLoading,
          isCbsasFluLoading: false,
          hasCbsasFluLoaded: true,
        }))
      })
      .catch((err) => {
        setCbsasFluLoading((activeCbsasFluLoading) => ({
          ...activeCbsasFluLoading,
          hasCbsasFluError: true,
          isCbsasFluLoading: false,
        }))
      })
  }, [activeCbsasFlu,token])

  const getListOfAcitveCbsasCovid = useCallback(() => {
    setCbsasCovidLoading((activeCbsasCovidLoading) => ({
      ...activeCbsasCovidLoading,
      isCbsasCovidLoading: true,
    }))
    fetch(`${activeCbsasCovid}`,{
      method: 'get',
      headers: {
        'Content-Type': 'application/json',        
        id_token: token,
      }
    })
      .then((res) => handleResponse(res))
      .then((response) => {
        setActiveCovidCbsas(Object.values(response))
        setCbsasCovidLoading((activeCbsasCovidLoading) => ({
          ...activeCbsasCovidLoading,
          isCbsasCovidLoading: false,
          hasCbsasCovidLoaded: true,
        }))
      })
      .catch((err) => {
        setCbsasCovidLoading((activeCbsasCovidLoading) => ({
          ...activeCbsasCovidLoading,
          hasCbsasCovidError: true,
          isCbsasCovidLoading: false,
        }))
      })
  }, [activeCbsasCovid])

  useEffect(() => {
    getListOfSubscriptions()
    getListOfAcitveCbsasFlu()
    getListOfAcitveCbsasCovid()
  }, [
    getListOfSubscriptions,
    getListOfAcitveCbsasFlu,
    getListOfAcitveCbsasCovid,
  ])
  const [getCbsaJsonFile, setCbsaJsonFile] = useState();
  useEffect(() => {
    let url = `${window.location.origin}/cb_2018_us_cbsa_20m_wkid102004.json`
    fetch(url)
      .then(function (res) {       
        return res.json();
      })
      .then(function (data) {
       setCbsaJsonFile(data);
      })
      .catch(function (err) {
        console.log(err, " error file load cb_2018_us_cbsa_20m_wkid102004 ");
      });
  }, []);
 

  return (
   
          <div className={styles.body}>
            <Flex gutter={3}>
              <Flex.Item span={12}>
                {loading.hasLoaded &&
                activeCbsasFluLoading.hasCbsasFluLoaded &&
                activeCbsasCovidLoading.hasCbsasCovidLoaded && getCbsaJsonFile ? (
                  <AddSubscriptionForm
                    existingRegions={subscriptions}
                    successRedirect={'/subscriptions'}
                    loadValues={state}
                    activeCbsas={{
                      FLU: activeFluCbsas,
                      'COVID-19': activeCovidCbsas,
                    }}
                    cbsaJson={getCbsaJsonFile}
                  />
                ) : (
                  <Spinner />
                )}
              </Flex.Item>
            </Flex>
          </div>
        //</div>
    //   </Content>
    // </Layout>
  )
}

export default AddSubscription
