import React from 'react'
import { useGeoState } from '../../components/GeoProvider'
import Tag from '../../components/Tag'

import statesMap from '../../utils/stateMap'

import styles from './CbsaStats.module.scss'
import statStyles from './CbsaStat.module.scss'

const StateStat = ({ state, mapOptions }) => {
  return (
    <div className={statStyles.base}>
      <div
        style={{
          display: 'inline-flex',
          width: '60%',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        title={state.name}
      >
        <span className={statStyles.btn}>{state.name}</span>
      </div>
      <div
        style={{
          display: 'inline-flex',
          width: '40%',
          justifyContent: 'flex-end',
        }}
      >
        <Tag
          dataValue={state}
          levelKey={mapOptions.tagKey}
          valueKey={mapOptions.tagValue}
          showNumbers={true}
        />
      </div>
    </div>
  )
}

const StateStats = ({ stateData, mapOptions }) => {
  const { displayDate, searchFilter, setSearchFilter } = useGeoState()

  const states = statesMap()
  const stateDataForDisplayDate = Object.entries(stateData[displayDate])

  const mappedStateData = stateDataForDisplayDate.map((state) => {
    const stateName = states[state[0]]
    return { ...state[1], name: stateName, identifier: state[0] }
  })

  const handleChange = (event) => {
    setSearchFilter(event.target.value)
  }

  let filteredStateData = mappedStateData
  if (searchFilter !== '') {
    // eslint-disable-next-line array-callback-return
    filteredStateData = mappedStateData.filter((cbsa) => {
      if (
        cbsa.name &&
        cbsa.name.toLowerCase().includes(searchFilter.toLowerCase())
      ) {
        return cbsa
      }
    })
  }
  // eslint-disable-next-line array-callback-return
  filteredStateData = filteredStateData.filter((cbsa) => {
    if (cbsa.name && cbsa.name.length > 1) {
      return cbsa
    }
  })

  return (
    <div className={styles.base}>
      <div className={styles.header}>
        <div>
          <h3>States</h3>
          <form>
            <input
              type="text"
              onChange={handleChange}
              placeholder="Filter States"
            />
          </form>
        </div>
        <div style={{ padding: '0.5rem 0.5rem' }}>
          <div style={{ display: 'inline-flex', width: '65%' }}>
            <h4>Area</h4>
          </div>
          <div
            style={{
              display: 'inline-flex',
              width: '35%',
              justifyContent: 'flex-end',
            }}
          >
            <h4>Risk</h4>
          </div>
        </div>
      </div>
      <div style={{ overflow: 'scroll', height: '100%' }}>
        {filteredStateData
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((state) => {
            return (
              <StateStat
                state={state}
                key={state.identifier}
                mapOptions={mapOptions}
              />
            )
          })}
      </div>
    </div>
  )
}

export default StateStats
