import React, { useState } from 'react'

import useGeoFetch from './useGeoFetch'

import useUHCGeoFetch from './useUHCGeoFetch'

const GeoContext = React.createContext()

function useGeoProvider(
  startDate,
  endDate,
  showingDate,
  countiesStartDate,
  countiesEndDate,
  hrrWeekStartDate,
  hrrWeekEndDate,
  options,
  weekEndDate,
  indicator,
  location,
  fDate,
  tDate,
  locationCd,
  regionName,
  geoLocationType,
  vulGetCount,
  showAddsubscribe,
  showRiskResult,
  showProviders,
  showFullTbl,
  showUHCCov
) {
  const [loadedDate] = useState(showingDate)
  const [UHCStateLoadedDate, setUHCStateLoadedDate] = useState(showingDate)
  const [UHCCountyLoadedDate, setUHCCountyLoadedDate] = useState(showingDate)
  const [dataStartDate, setDataStartDate] = useState(startDate)
  const [startDateCounties, setStartDateCounties] = useState(countiesStartDate)
  const [hrrDataWeekStartDate, setHrrDate] = useState(hrrWeekStartDate)
  const [hrrDataWeekEndDate, setHrrEndDate] = useState(hrrWeekEndDate)
  const [dataEndDate, setDataEndDate] = useState(endDate)
  const [dataEndDateCounties, setDataEndDateCounties] =
    useState(countiesEndDate)
  const [displayDate, setDisplayDate] = useState(showingDate)
  const [displayWeek, setDisplayWeek] = useState('')
  const [searchFilter, setSearchFilter] = useState('')
  const [showWeek, setShowWeek] = useState(0)
  const [timelineIsPaused, setTimelineIsPaused] = useState(true)
  const [countyDataLoaded, setCountyDataLoaded] = useState(false)
  const [mapOptions, setMapOptions] = useState(options)
  const [dataSelected, setDataSelected] = React.useState('revised_accuracy')
  const [regionSelected, setRegionSelected] = React.useState('state')
  const [isChecked, setIsChecked] = useState(false)
  const [lastweekEndDate, setLastWeekEndDate] = useState(weekEndDate)
  const [indicatorName, setIndicatorName] = useState(indicator)
  const [locationName, setLocationName] = useState(location)
  const [fromDate, setFromDate] = useState(fDate)
  const [toDate, setToDate] = useState(tDate)
  const [regionSelectedName, setRegionSelectedName] = useState(regionName)
  const [locationCode, setLocationCode] = useState(locationCd)
  const [locationType, setLocationType] = useState(geoLocationType)
  const [vulCount, setVulCount] = useState(vulGetCount)
  const [diseaseSelected, setDiseaseSelected] = useState('flu')
  const [claimsFiled, setClaimsFiled] = useState()  
  
  const [showAddsubscribePop, setShowAddsubscribePop] = useState(false)
  const[getRiskResult, setRiskResult]=useState()
  const [showProvidersMap, setShowProvidersMap] = useState(showProviders)
  const [showFullTable, setShowFullTable] = useState(showFullTbl)
  const [showUHCCoverage, setUHCCoverage] = useState(showUHCCov)
  const [getMapZoom, setMapZoom] = useState(false)
  const [seasonSelected, setSeasonSelected] = useState('2024-25')
  const [posSelected, setPosSelected] = useState(0)
  const [lobSelected, setLobSelected] = useState(0)
  const [diseaseSelectedSurv, setDiseaseSelectedSurv] = useState('all')

  return {
    loadedDate,
    UHCStateLoadedDate,
    UHCCountyLoadedDate,
    dataStartDate,
    startDateCounties,
    dataEndDate,
    dataEndDateCounties,
    hrrDataWeekStartDate,
    hrrDataWeekEndDate,
    displayDate,
    displayWeek,
    searchFilter,
    showWeek,
    timelineIsPaused,
    countyDataLoaded,
    mapOptions,
    dataSelected,
    regionSelected,
    isChecked,
    lastweekEndDate,
    indicatorName,
    locationName,
    fromDate,
    toDate,
    regionSelectedName,
    locationCode,
    locationType,
    vulCount,
    claimsFiled,
    diseaseSelected,
    showAddsubscribePop,
    getRiskResult,
    showProvidersMap,
    showFullTable,
    showUHCCoverage,
    seasonSelected,
    posSelected,
    diseaseSelectedSurv,
    lobSelected,
    getMapZoom,
    setLocationType,
    setLocationCode,
    setRegionSelectedName,
    setToDate,
    setFromDate,
    setLocationName,
    setIndicatorName,
    setLastWeekEndDate,
    setUHCStateLoadedDate,
    setUHCCountyLoadedDate,
    setDataStartDate,
    setStartDateCounties,
    setDataEndDate,
    setDataEndDateCounties,
    setHrrDate,
    setHrrEndDate,
    setDisplayDate,
    setDisplayWeek,
    setSearchFilter,
    setShowWeek,
    setTimelineIsPaused,
    setCountyDataLoaded,
    setMapOptions,
    setDataSelected,
    setRegionSelected,
    setIsChecked,
    setVulCount,
    setDiseaseSelected,
    setShowAddsubscribePop,
    setRiskResult,
    setClaimsFiled,
    setShowProvidersMap,
    setShowFullTable,
    setUHCCoverage,
    setSeasonSelected,
    setPosSelected,
    setDiseaseSelectedSurv,
    setLobSelected,
    setMapZoom
  }
}

const GeoProvider = ({
  children,
  startDate,
  startDateCounties,
  endDate,
  endDateCounties,
  displayDate,
  hrrDataWeekStartDate,
  hrrDataWeekEndDate,
  mapOptions,
  lastweekEndDate,
  indicatorName,
  locationName,
  fromDate,
  toDate,
  regionSelectedName,
  locationCode,
  locationType,
  vulCount,
  claimsFiled,
  diseaseSelected,
  showAddsubscribePop,
  getRiskResult,
  showProvidersMap,
  showFullTable,
  showUHCCoverage,
  seasonSelected,
  posSelected,
  diseaseSelectedSurv,
  lobSelected,
  getMapZoom,
}) => {
  const dataFilter = useGeoProvider(
    startDate,
    endDate,
    displayDate,
    startDateCounties,
    endDateCounties,
    hrrDataWeekStartDate,
    hrrDataWeekEndDate,
    mapOptions,
    lastweekEndDate,
    indicatorName,
    locationName,
    fromDate,
    toDate,
    regionSelectedName,
    locationCode,
    locationType,
    vulCount,
    claimsFiled,
    diseaseSelected,
    showAddsubscribePop,
    getRiskResult,
    showProvidersMap,
    showFullTable,
    showUHCCoverage,
    seasonSelected,
    posSelected,
    diseaseSelectedSurv,
    lobSelected,
    getMapZoom
  )

  return (
    <GeoContext.Provider value={dataFilter}>{children}</GeoContext.Provider>
  )
}

export const useGeoState = () => {
  const context = React.useContext(GeoContext)
  if (!context) {
    throw new Error('useGeoState must be used within an GeoProvider')
  }
  return context
}

export { GeoProvider as default, useGeoFetch, useUHCGeoFetch }
